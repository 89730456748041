import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import "../../../styles/driver.css";
// Tostify
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BottomPanel from "../common/bottomPanel";
import SidePanel from "../common/sidePanel";
import { addDrop, driverHistory, reRunDrops } from "../../../api/apiDriver";
// import { CiEdit } from "react-icons/ci";
import Loading from "../../common/Loading";

const DriverHistory = () => {
  const [dataS, setDataS] = useState([]);
  const [dropSearch, setDropSearch] = useState("");
  const [dropAddress, setDropAddress] = useState("");
  const [dropDate, setDropDate] = useState("");
  const driverId = localStorage.getItem("userID");
  const [dataLoading, setDataLoading] = useState(true);
  // const [dropId, setDropId] = useState(null);
  // const navigate = useNavigate();

  useEffect(() => {
    const showDriverHistory = async () => {
      try {
        const data = await driverHistory(driverId);
        if (data) {
          setDataS(data.driverInfo);
          setDataLoading(false);
        }

        // setTimeout(() => {
        //   setDataS(data.driverInfo);
        //   setDataLoading(false);
        // }, 100);
      } catch (err) {
        console.error("Driver History Error " + err);
      }
    };

    showDriverHistory();

  }, [driverId]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };

  const formatSearchDate = (timestamp) => {
    const date = new Date(timestamp*1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const repeatRun = async (index) => {
    try {
      const response = await addDrop(
        driverId,
        dataS[index].DropName,
        dataS[index].Location
      );
      if (response.success === true) {
        toast.success("Successfully added drop", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // re-run yesterday drops
  const reRunYesterdayDrops = async () => {
    try {
      if (dropDate || dropSearch || dropAddress) {
        const response = await reRunDrops(driverId, dropDate, dropSearch, dropAddress);
        if (response.success === true) {
          toast.success("Successfully Created Yesterday Drops", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error("Failed to Create Drop", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } else {
        toast.error("Internal Error - Please Contact Administrator", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // drop edit navigation
  // const selectedDrop = async (index) => {
  //   try {
  //     setDropId(dataS[index].DropID);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // redirection
  // useEffect(() => {
  //   try {
  //     if (dropId !== null && dataS !== null) {
  //       navigate(`${dropId}`);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [dropId, dataS, navigate]);
 
  return (
    <>
      {/* Desktop version */}
      <div className="driver__history__container__desk">
        <SidePanel />
        <div className="driver__history__container">
          <h1 className="title">DRIVER HISTORY</h1>
          <h4 className="heading-3">Filter by</h4>
          <div className="driver__history__search">
            <input
              type="text"
              className="input"
              placeholder="Drop Name"
              onChange={(e) => {
                setDropSearch(e.target.value.toLowerCase());
              }}
            />
            <input
              type="text"
              className="input"
              placeholder="Address"
              onChange={(e) => {
                setDropAddress(e.target.value.toLowerCase());
              }}
            />
          </div>

          <div className="driver__history__table">
            <table>
              <tr className="heading-2 dht__heading">
                <th></th>
                <th>DROP NAME</th>
                <th>RUNNING DATE</th>
                <th>DELIVERED TIME</th>
                <th>ADDRESS</th>
                <th>DELIVERY STATE</th>
                <th>REPEAT RUN</th>
              </tr>
              {dataS
                .filter((drop) => {
                  const searchDrop =
                    drop.DropName.toLowerCase().includes(dropSearch);
                  const searchAddress =
                    drop.Location.toLowerCase().includes(dropAddress);
                  const searchDate = formatDate(drop.DateTime).includes(
                    dropDate
                  );

                  return dropSearch === "" &&
                    dropAddress === "" &&
                    dropDate === ""
                    ? drop
                    : searchDrop && searchAddress && searchDate;
                })
                .slice()
                .reverse()
                .map((drop, index) => (
                  <tr className="text" key={index}>
                    <td>{drop.index}</td>
                    <td>{drop.DropName}</td>
                    <td>{formatDate(drop.DateTime)}</td>
                    <td>{formatTime(drop.DateTime)}</td>
                    <td>{drop.Location}</td>
                    <td>{drop.DropStatus}</td>
                    <td
                      className="driver_repeat_run"
                      onClick={() => repeatRun(index)}
                    >
                      Repeat Run
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
      {/* Mobile version */}
      {dataLoading === true ? (
        <Loading />
      ) : (
        <div className="driver__history__container__mob">
          <h1 className="title">DRIVER HISTORY</h1>

          {/* <button className="button button-1 btn__repeat_all__runs">
          Repeat all Runs
        </button> */}
          <div className="driver__history__search__date">
            <p className="heading-3">Vendor</p>
            <input
              type="text"
              className="input"
              onChange={(e) => setDropSearch(e.target.value.toLowerCase())}
            />
          </div>

        <div className="driver__history__search__address">
          <p className="heading-3">Address</p>
          <input
            type="text"
            className="input"
            onChange={(e) => setDropAddress(e.target.value.toLowerCase())}
          />
        </div>
        <div className="driver__history__search__date">
          <p className="heading-3">Date</p>
          <input
            type="date"
            className="input"
            onChange={(e) => setDropDate(e.target.value)}
          />
        </div>

          <button
            onClick={reRunYesterdayDrops}
            className="button button-1 re-run-button"
          >
            {" "}
            Re Run Drops{" "}
          </button>

          {dataS
            .filter((drop) => {
              const searchDrop =
                drop.DropName.toLowerCase().includes(dropSearch);
              const searchAddress =
                drop.Location.toLowerCase().includes(dropAddress);
              const searchDate = formatSearchDate(drop.DateTime).includes(
                dropDate
              );
              return dropSearch === "" && dropAddress === "" && dropDate === ""
                ? drop
                : searchDrop && searchAddress && searchDate;
            })
            .slice()
            .reverse()
            .map((drop, index) => (
              <div
                key={index}
                className="current__drop__card driver__history__card"
              >
                <div className="common__column current__drop__col1">
                  <div className="current-drop-r1">
                    <h2 className="heading-2">{drop.DropName}</h2>
                    {/* {drop.DropStatus === "Done" ? (
                      <button
                        className="button button-2"
                      >
                        <CiEdit onClick={() => selectedDrop(index)} size={20} />
                      </button>
                    ) : null} */}
                  </div>
                  <p className="text">{drop.Location}</p>
                  <div className="common__row driver-his-date-time">
                    <p className="heading-3">{formatDate(drop.DateTime)}</p>
                    <p className="heading-3">{formatTime(drop.DateTime)}</p>
                  </div>
                </div>
                <div className="common__row current__drop__col2">
                  <button
                    onClick={() => repeatRun(index)}
                    className="button button-2"
                  >
                    Repeat Run
                  </button>
                </div>
              </div>
            ))}
          <ToastContainer className="notifications" />
          <BottomPanel />
        </div>
      )}
    </>
  );
};

export default DriverHistory;
