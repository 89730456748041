import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  currentDrops,
  deleteDropCall,
  deliveryComplete,
  inProgress,
  sendMessage,
  dropOrdering,
} from "../../../api/apiDriver";
import { Reorder } from "framer-motion";
import { useDropContext } from "../../../provider/AddDropProvider";
import { MdDeleteOutline } from "react-icons/md";
import { ToastContainer, toast, Bounce } from "react-toastify";
import NoData from "../dashboard/NoData";
const CurrentDrop = ({ setDropDeleted, dropDeleted }) => {
  const driverId = localStorage.getItem("userID");
  const [dropData, setDropData] = useState([]);
  //eslint-disable-next-line
  const [dropLocation, setDropLocation] = useState("");
  const [dropStatus, setDropStatus] = useState("");
  const [dropId, setDropId] = useState("");
  const [dropName, setDropName] = useState("");
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const { addDrop } = useDropContext();
  const [dropDeleteId, setDropDeleteId] = useState("");
  const { deleteDrop } = useDropContext(); //setDeleteDrop remove due to build issues.
  const [deleteDropCurrentStateChange, setDeleteDropCurrentStateChange] =
    useState(0);
  const [isDropData, setIsDropData] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragItemStateCheck, setDragItemStateCheck] = useState(true);
  // Run when dropdata
  useEffect(() => {
    const showCurrentDrops = async () => {
      try {
        const data = await currentDrops(driverId);
        if (data === undefined) {
          setIsDropData(false);
        } else {
          setIsDropData(true);
          // API retuning the object convert into the array
          const driverInfoArray = Array.isArray(data.driverInfo)
            ? data.driverInfo
            : [data.driverInfo];
          setDropData(driverInfoArray);
        }
      } catch (err) {
        console.error(err);
      }
    };

    showCurrentDrops();
  }, [
    addDrop,
    driverId,
    deleteDrop,
    deleteDropCurrentStateChange,
    dropDeleteId,
    dragItemStateCheck,
  ]);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      // eslint-disable-next-line
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };
  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };
  const selectDrop = async (index) => {
    if (index != null && dropData != null) {
      setDropLocation(dropData[index].Location);
      setDropId(dropData[index].DropID);
      setDropStatus(dropData[index].DropStatus);
      setDropName(dropData[index].DropName);
      await makeDropInProgress(dropId);
    }
  };
  const makeDropInProgress = async (dropId) => {
    try {
      if (dropStatus === "Todo") {
        await inProgress(dropId);
      }
    } catch (err) {
      console.error(err);
    }
  };
  //eslint-disable-next-line
  const addImages = async (e) => {
    try {
      const files = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...files]);
      if (images !== []) {
        await sendDropProof();
      } else {
        alert("Add images to make drop complete");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const sendDropProof = async () => {
    try {
      const message = `${dropName} drop is completed`;
      const response = await sendMessage(driverId, message, images);
      if (response.success === true && dropId !== "") {
        await deliveryComplete(dropId);
      } else {
        alert("Please select drop first");
      }
    } catch (err) {
      console.error(err);
    }
  };
  // Drop Delete
  const selectDropDelete = async (index) => {
    if (dropData !== null || index != null) {
      try {
   
        setDropDeleteId(sortedDropData[index].DropID);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {

      if (dropDeleteId) {
    
        const dropDeleteTrigger = async () => {
          try {
            const response = await deleteDropCall(dropDeleteId);
            if (response.success === true) {
              toast.success("Successfully Deleted Drop", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
              setDeleteDropCurrentStateChange((prevImages) => prevImages + 1);
              dropDeleted === true
                ? setDropDeleted(false)
                : setDropDeleted(true);
            }
          } catch (err) {
            console.error("Drop Delete Function Error", err);
          }
        };
        dropDeleteTrigger();
      }
 
  }, [dropDeleteId, dropDeleted, setDropDeleted]);
  // Mobile - Direct to specific component
  const selectDropMob = async (index) => {
    if (dropData !== null || index != null) {
      try {
        setDropId(sortedDropData[index].DropID);
        setDropLocation(sortedDropData[index].Location);
        setDropStatus(sortedDropData[index].DropStatus);
        setDropName(sortedDropData[index].DropName);
      } catch (err) {
        console.error(err);
      }
    }
  };
  // Navigate to specific drop
  useEffect(() => {
    if (hasMounted) {
      try {
        if (dropId != null) {
          const inProgressTrigger = async () => {
            try {
              await inProgress(dropId);
            } catch (err) {
              console.error(err);
            }
          };

          inProgressTrigger();
          navigate(`/driver/${dropId}`);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setHasMounted(true);
    }
    // eslint-disable-next-line
  }, [dropId, navigate]);
  // const reorder = (newOrder) => {
  //   console.log("reorder function triggered");
  //   const currentOrderMap = dropData.reduce((acc, item) => {
  //     acc[item.DropID] = parseInt(item.DropOrder);
  //     return acc;
  //   }, {});
  //   const newOrderMap = newOrder.reduce((acc, item, index) => {
  //     acc[item.DropID] = index + 1;
  //     return acc;
  //   }, {});
  //   for (let i = 0; i < newOrder.length; i++) {
  //     const currentOrder = currentOrderMap[newOrder[i].DropID];
  //     const newOrderValue = newOrderMap[newOrder[i].DropID];
  //     if (currentOrder !== newOrderValue) {
  //       // Function to call backend
  //       // updateOrderInBackend(currentOrder, newOrderValue);
  //     }
  //   }
  //   setDropData(newOrder);
  // };
  const sortedDropData = [...dropData].sort((a, b) => {
    if (a.DropStatus === "Done" && b.DropStatus !== "Done") return 1;
    if (a.DropStatus !== "Done" && b.DropStatus === "Done") return -1;
    return 0;
  });

  //handle drag scoll
  // const handleDragStart = () => {
  //   lockScroll();
  // };
  // const handleDragEnd = () => {
  //   unlockScroll();
  // };

  const reorder = (index) => {
    setDraggedItem(index);
  };

  const reOrderEnd = async (index) => {
    if (draggedItem !== index) {
      const currentOrderId = sortedDropData[draggedItem].DropOrder;
      const changeOrderId = sortedDropData[index].DropOrder;
      console.log(sortedDropData[index].DropStatus)
      try {
        if (currentOrderId !== changeOrderId) {
          console.log(`current order Id: ${currentOrderId} change order Id: ${changeOrderId}` )
          const response = await dropOrdering(currentOrderId, changeOrderId);
          if (response) {
            dragItemStateCheck === true
              ? setDragItemStateCheck(false)
              : setDragItemStateCheck(true);
            console.log(response);
          } else {
            console.error("No response from drop Ordering");
          }
        }
      } catch (err) {
        console.error("reordering function error", err);
      }
    }
  };


  return (
    <>
      <div className="current__drop__container__desktop">
        {/* <div className="cdc__details">
          <div className="current__drop__location">
            <h3 className="heading-2">CURRENT DROP</h3>
            <div className="cdl__details">
              <div className="cdl__title">
                <h4 className="heading-2">Location : </h4>
              </div>
              <div>
                <h4 className="heading-3">{dropLocation}</h4>
              </div>
              <div className="cdl__location">
                <p className="text">{dropLocation}</p>
              </div>
              <div>
                <p>{dropName}</p>
              </div>
            </div>
            <button className="button button-1">Open Google Maps</button>
          </div>
          <div className="current__drop__image__container">
            {images.map((image, index) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt={`Preview ${dropName}`}
                className="current-drop-image"
              />
            ))}
            <label className="button button-1" for="input-file">
              Delivery Proof
            </label>
            <input
              type="file"
              accept="image/"
              id="input-file"
              hidden
              onChange={addImages}
            />
          </div>
          <div className="current__drop__comment">
            <input type="text" className="input" placeholder="Comments" />
            <button className="button button-1">Submit Comment</button>
          </div>
        </div> */}
        <div className="cdc__table">
          <table>
            <tr className="heading-3">
              <th></th>
              <th>DROP NAME</th>
              <th>RUNNING DATE</th>
              <th>DELIVARY TIME</th>
              <th>ADDRESS</th>
              <th>DELIVERY STATE</th>
            </tr>
            <tbody>
              {sortedDropData.map((drop, index) => (
                <tr
                  key={index}
                  onClick={() => selectDrop(index)}
                  className="text drop__table__row"
                >
                  <td>{index + 1}</td>
                  <td>{drop.DropName}</td>
                  <td>{formatDate(drop.DateTime)}</td>
                  <td>{formatTime(drop.DateTime)}</td>
                  <td>{drop.Location}</td>
                  <td className="delivary__state__common">{drop.DropStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* loading component */}
      {/* <ClipLoader color={'#000000'} size={150} loading={loadingInProgress}/> */}
      {/* Droppable Component Start */}

      {isDropData === true ? (
        <Reorder.Group axis="y" values={sortedDropData} onReorder={setDropData}>
          {sortedDropData.map((drop, index) => (
            <Reorder.Item
              key={drop.DropOrder}
              value={drop}
              onDragStart={() => reorder(index)}
              onDragEnd={() => reOrderEnd(index)}
            >
              <div className="current__drop__container">
                <div key={dropData.DropOrder} className="current__drop__card">
                  <div className="common__column current__drop__col1">
                    <div className="current-drop-row">
                      <h2 className="heading-2">{drop.DropName}</h2>
                      {drop.DropStatus === "InProgress" ? (
                        <button className="button">
                          <MdDeleteOutline
                            size={20}
                            onClick={() => selectDropDelete(index)}
                          />
                        </button>
                      ) : null}
                    </div>
                    <p
                      className={`text current-drop-status ${
                        drop.DropStatus === "Todo"
                          ? "current-drop-status-todo"
                          : drop.DropStatus === "InProgress"
                          ? "current-drop-status-inprogress"
                          : drop.DropStatus === "Done"
                          ? "current-drop-status-completed"
                          : "current-drop-status"
                      }`}
                    >
                      {drop.DropStatus}
                    </p>
                    <p className="text">{formatDate(drop.DateTime)}</p>
                    <p className="text">{formatTime(drop.DateTime)}</p>
                  </div>
                  <div className="common__column current__drop__col2">
                    <p className="text">{drop.Location}</p>
                    <button
                      className="current-drop-dd-button"
                      onClick={() => selectDropMob(index)}
                    >
                      Drop&nbsp;Details
                    </button>
                  </div>
                </div>
              </div>
            </Reorder.Item>
          ))}
          <ToastContainer />
        </Reorder.Group>
      ) : (
        <NoData />
      )}

      {/* Draggable components End */}
    </>
  );
};
export default CurrentDrop;
