import React, { useEffect, useState } from "react";
import { getDrops } from "../../../api/apiService";

const DailyProgress = () => {
  const [todo, setTodo] = useState(0);
  const [inProgress, setInProgress] = useState(0);
  const [completed, setCompleted] = useState(0);

  const getAllDropData = async () => {
    try {
      const response = await getDrops();
      console.log(response);

      if (response.success === true) {
        const arrayLength = response.driverInfo.length;
        let todoCount = 0;
        let inProgressCount = 0;
        let completedCount = 0;
        for (let i = 0; i < arrayLength; i++) {
          if (response.driverInfo[i].DropStatus === "Todo") {
            todoCount++;
          } else if (response.driverInfo[i].DropStatus === "InProgress") {
            inProgressCount++;
          } else {
            completedCount++;
          }
        }
        setTodo(todoCount);
        setInProgress(inProgressCount);
        setCompleted(completedCount);
      } else {
        console.log("response is not successful");
      }
    } catch (err) {
      console.log(err);
      if (err === "TypeError") {
        console.log("Error Identified");
      }
      // window.location.href = "/";
    }
  };

  useEffect(() => {
    try {
      getAllDropData();
    } catch (err) {
      console.log(err);
      // window.location.href = "/";
    }
  }, []);

  return (
    <section>
      <div className="daily__main__container">
        <h2 className="heading-2">DAILY PROGRESS</h2><br></br>
        <div className="daily__numbers__container">
          <div className="daily__container">
            <div className="todo_container">
              <p className="title">{todo}</p>
            </div>
            <p className="heading-2">TODO</p>
          </div>
          <div className="daily__container">
            <div className="inprogress__container">
              <p className="title">{inProgress}</p>
            </div>
            <p className="heading-2">IN PROGRESS</p>
          </div>
          <div className="daily__container">
            <div className="dropped__container">
              <p className="title">{completed}</p>
            </div>
            <p className="heading-2">DROPPED</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DailyProgress;
