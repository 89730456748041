import React from "react";
import { Link } from "react-router-dom";

// icons
import { MdSpaceDashboard } from "react-icons/md";
import { GrDeliver } from "react-icons/gr";
import { AiFillMessage } from "react-icons/ai";
import { IoSettingsSharp } from "react-icons/io5";

const BottomPanel = () => {



  return (
    <>
      <div className="bottom__panel__container">
        <Link to="/driver">
          <button>
            <MdSpaceDashboard size={20} className="bottom__panel__icon" />
          </button>
        </Link>
        <Link to="/driver/history">
          <button>
            <GrDeliver size={20} className="bottom__panel__icon" />
          </button>
        </Link>
        <Link to='/driver/contact'>
          <button>
            <AiFillMessage size={20} className="bottom__panel__icon" />
          </button>
        </Link>
        <Link to='/driver/settings'>
          <button>
            <IoSettingsSharp size={20} className="bottom__panel__icon" />
          </button>
        </Link>
      </div>
    </>
  );
};

export default BottomPanel;
