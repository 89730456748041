import React from "react";
import Sidepanel from "../common/sidepanel";
import BottomPanel from "../common/bottomPanel";
import { useEffect } from "react";
// icons
import { useParams } from "react-router-dom";
import { getSpecificDropData } from "../../../api/apiService";
import { useState } from "react";

const SelectedDrop = () => {
  const { id } = useParams();
  const dropId = id;

  const [driverName, setDriverName] = useState("Loading...");
  const [dropDate, setDropDate] = useState("Loading...");
  const [dropTime, setDropTime] = useState("Loading...");
  const [dropComment, setDropComment] = useState("Drop is Not finished yet!");
  const [dropName, setDropName] = useState("Loading...");
  const [dropStatus, setDropStatus] = useState("Loading...");
  const [dropLocation, setDropLocation] = useState("Loading...");
  const [proofImageArray, setProofImageArray] = useState([]);
  const [proofImageAvaiable, setProofImageAvailable] = useState(false);

  useEffect(() => {
    try {
      const showDrop = async () => {
        const data = await getSpecificDropData(dropId);
        if (data) {
          if (data.success === true) {
            console.log(data);
            setDriverName(data.dropInfo[0].DriverName);
            setDropDate(formatDate(data.dropInfo[0].DateTime));
            setDropTime(formatTime(data.dropInfo[0].DateTime));
            setDropName(data.dropInfo[0].DropName);
            setDropStatus(data.dropInfo[0].DropStatus);
            setDropLocation(data.dropInfo[0].Location);
            if (
              data.dropInfo[0].DropStatus === "Done" &&
              data.dropInfo[0].ProofImageBlob
            ) {
              setProofImageArray(data.dropInfo[0].ProofImageBlob);
            }

            if (data.dropInfo[0].DropStatus === "Done") {
              setDropComment(data.dropInfo[0].DropComment);
            }
          }
        }
      };
      showDrop();
    } catch (err) {
      console.error(err);
    }
  }, [dropId]);

  // for(let i = 0; i < proofImageArray.length; i++){
  //   console.log(proofImageArray[i].ProofImageBlob);
  //    setProofImageURL(proofImageArray[i].ProofImageBlob)
  // }
  useEffect(() => {
    if (proofImageArray[0]) {
      setProofImageAvailable(true);
    }
  }, [proofImageArray]);

  // Convert timestamp to data and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };

  return (
    <>
      <div className="selected__drop__main__container">
        <Sidepanel />
        <div className="selected__drop__container">
          <div className="selected__drop__heading">
            <h3 className="title">DASHBOAORD</h3>
            <h3 className="heading-2">{dropName}</h3>
            <p
              className={`drop-table-status heading-3 ${
                dropStatus === "Todo"
                  ? "drop-status-todo"
                  : dropStatus === "InProgress"
                  ? "drop-status-inprogress"
                  : "drop-status-completed"
              }`}
            >
              {dropStatus}
            </p>
          </div>
          <div className="selected__drop__details__container">
            <div className="selected__drop__details">
              <div className="common__row">
                <h3 className="heading-3">Driver Name: </h3>
                <p className="text">{driverName}</p>
              </div>
              <div className="common__row">
                <h3 className="heading-3">Date: </h3>
                <p className="text">{dropDate}</p>
              </div>
              <div className="common__row">
                <h3 className="heading-3">Time: </h3>
                <p className="text">{dropTime}</p>
              </div>
              <div className="common__row">
                <h3 className="heading-3">Location </h3>
                <div className="address__lines">
                  <p className="text">{dropLocation}</p>
                </div>
              </div>
              <div className="common__row">
                <h3 className="heading-3">Drop Comment </h3>
                <div className="address__lines">
                  <p className="text">{dropComment}</p>
                </div>
              </div>
            </div>
            <div className="selected__drop__images">
              <h2 className="heading-3">Delivary Proof</h2>
              <div className="selected__drop__image">
                <img
                  src={
                    proofImageAvaiable === true
                      ? proofImageArray[0].ProofImageBlob
                      : ""
                  }
                  alt="Drop Proof"
                />
              </div>
              {/* <div className="selected__drop__image__2">Image 2</div>
              <div className="selected__drop__image__3">Image 3</div>
              <div className="selected__drop__image__4">Image 4</div> */}
              {/* <div className="selected__drop__images__share">
                <FaShareAlt />
                <p className="heading-3">Share</p>
              </div>
              <div className="selected__drop__images__share__buttons">
                <button className="text">WhatsApp</button>
                <button className="text">Email</button>
                <button className="text">SMS</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="selected__drop__details__container__mobile">
          <h1 className="title">DASHBOARD</h1>
          <h2 className="heading-2">{dropName}</h2>
          <p className="dropped-tag heading-3 s__d__d__tagline">{dropStatus}</p>
          <div className="common__row selected__drop__details__sc">
            <h3 className="heading-3">Driver Name: </h3>
            <h4 className="text">{driverName}</h4>
          </div>
          <div className="common__row selected__drop__details__sc">
            <h3 className="heading-3">Running Date: </h3>
            <h4 className="text">{dropDate}</h4>
          </div>
          <div className="common__row selected__drop__details__sc">
            <h3 className="heading-3">Location: </h3>
            <div className="common__column">
              <h4 className="text">{dropLocation}</h4>
            </div>
          </div>
          <div className="common__row selected__drop__details__sc">
            <h3 className="heading-3">Delivary Time: </h3>
            <h4 className="text">{dropTime}</h4>
          </div>
          <div className="common__row selected__drop__details__sc">
            <h3 className="heading-3">Comment </h3>
            <h4 className="text">{dropComment}</h4>
          </div>
          <div className="common__column selected__drop__details__sc">
            <h3 className="heading-3">Delivary Proof: </h3>
          </div>
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default SelectedDrop;
