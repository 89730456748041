import React, { useCallback, useEffect, useState} from "react";
import "../../../styles/driver.css";
import { useParams } from "react-router-dom";
import { currentDrops, deliveryComplete } from "../../../api/apiDriver";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { IoMdAttach } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
const IndividualDrop = () => {
  const driverId = localStorage.getItem("userID");
  const { id } = useParams();
  const [dropData, setDropData] = useState([]);
  const [images, setImages] = useState([]);
  const [dropId, setDropId] = useState("");
  const [dropName, setDropName] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [dropMessage, setDropMessage] = useState();
  const [dropStatus, setDropStatus] = useState("Todo");
  const [base64Images, setBase64Images] = useState([]);
  const [imageObject, setImageObject] = useState(null);
  const [reRunBase64, setReRunBase64] = useState(0);
  const [dropCompletedExecuted, setDropCompletedExecuted] = useState(true);
  const navigate = useNavigate();

  const openCamera = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera, // Opens the camera directly
      });
      addImages(image.base64String);
      // You can use image.webPath to display or upload the photo
    } catch (error) {
      console.error("Error taking photo:", error);
    }
  };
  const openGallery = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Photos, // Opens the gallery directly
      });
      addImages(image.base64String);
      console.log("Selected Image:", image.base64String);
      // You can use image.webPath to display or upload the photo
    } catch (error) {
      console.error("Error selecting photo:");
    }
  };
  // Retrive All Drop Data
  const showCurrentDrops = useCallback(async () => {
    try {
      const data = await currentDrops(driverId);
      if (data) {
        const arrayLength = data.driverInfo.length;
        if (arrayLength > 0) {
          for (let i = 0; i < arrayLength; i++) {
            if (data.driverInfo[i].DropID === id) {
              try {
                setDropData(data.driverInfo[i]);
                setDropId(data.driverInfo[i].DropID);
                setDropName(data.driverInfo[i].DropName);
                setDropLocation(data.driverInfo[i].Location);
                setDropStatus(data.driverInfo[i].DropStatus);
              } catch (err) {
                console.error(err);
                throw new Error("Error Assigning Data");
              }
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [driverId, id]);

  useEffect(() => {
    showCurrentDrops();
    setDropCompletedExecuted(false);
  }, [dropId, showCurrentDrops, dropCompletedExecuted]);

  // Connvert timestamp to data and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };

  // add image to UI and base64 array
  const addImages = async (base64String) => {
    try {
      setImages((prevImages) => [...prevImages, base64String]);
      if (base64String) {
        setImageObject({ imageBlob: base64String });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Call to base64 and add images to the array
  useEffect(() => {
    if (imageObject) {
      setBase64Images((prevImages) => [...prevImages, imageObject]);
    }
  }, [imageObject, reRunBase64]);

  const dropCompleted = async (e) => {
    e.preventDefault();

    try {
      if (base64Images.length > 0) {
        if (dropData.DropStatus !== "Done") {
          setReRunBase64(reRunBase64 + 1);
          const response = await deliveryComplete(
            dropId,
            dropMessage,
            base64Images
          );

          if (response.success === true) {
            toast.success("Drop Completed", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            setDropCompletedExecuted(true);
            navigate("/driver");
            setDropStatus("Done");
          }
        } else {
          toast.warning("Drop Already Completed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } else {
        toast.warning("Add Drop Images to complete the Drop", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Open in Google maps
  const openInGoogleMaps = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${dropLocation}`
    );
  };

  // drop confirm image delete
  const handleDeleteImage = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages);
    setBase64Images(updatedImages);
  };

  //navigate to back
  const handleBackNavigate = () => {
    navigate("/driver");
  };

  return (
    <>
      <div className="individual__drop__container">
        <div className="drop-container-heading">
          <ArrowBackIosRoundedIcon onClick={handleBackNavigate} />
          <h1 className="title idc-title">DASHBOARD</h1>
        </div>

        <div className="individual__drop__time">
          <div className=" individual-drop-data">
            <div>
              <h2 className="heading-1">{dropName}</h2>
              <p
                className={`text individual-drop-state ${
                  dropData.DropStatus === "Done"
                    ? "individual-drop-state-completed"
                    : "individual-drop-state-inprogress"
                }`}
              >
                {dropData.DropStatus === "Todo" ? "In Progress" : dropStatus}
              </p>
            </div>

            <h2 className="heading-3">{formatDate(dropData.DateTime)} </h2>

            <h2 className="heading-3">{formatTime(dropData.DateTime)}</h2>
            <p className="heading-3">{dropData.Location}</p>
          </div>
        </div>
        <div className="individual__drop__address"></div>

        <button className="button button-1" onClick={openInGoogleMaps}>
          Open in Google Maps
        </button>

        <div className="individual__drop__image__container">
          {images.map((image, index) => (
            <div className="image-container">
              <img
                key={index}
                src={`data:image/jpeg;base64,${image}`}
                alt={`Preview ${dropName}`}
                className="current-drop-image"
              />
              <button
                className="delete-image-button"
                onClick={() => {
                  handleDeleteImage(index);
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>
        <div className="add_comment_drop_finish">
          <h3 className="heading-2">Add Comment</h3>
          <textarea
            type="textarea"
            className="add_comment"
            rows="10"
            onChange={(e) => setDropMessage(e.target.value)}
          />
          <div className="complete_drop">
            <label
              htmlFor="upload_image"
              className="button button-1 individual-drop-upload"
            >
              <IoMdAttach size={20} />
            </label>
            <input
              type="file"
              accept="image/"
              id="upload_image"
              hidden
              // onChange={handleImages()}
            />
            <button className="button button-1" onClick={dropCompleted} disabled={base64Images.length === 0}>Complete Drop</button>
            <div className="complete_drop"> </div>

            <button className="button button-1 show__mobile" onClick={openCamera}>
              Take Photo
            </button>
            <button className="button button-1" onClick={openGallery}>
            <IoMdAttach size={20} /> Select from Gallery
            </button>
            <button className="button button-1" onClick={dropCompleted}>
              Complete Drop
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
   
    </>
 
  );
};

export default IndividualDrop;
